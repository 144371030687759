/* ./src/index.css */
@import url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400;500;600;700&family=Space+Mono:wght@400;700&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

html {
  scroll-behavior: smooth;
}

@layer base {
  h1 {
    @apply text-3xl font-instrument;
  }
  h2 {
    @apply text-2xl font-instrument;
  }
  h3 {
    @apply font-instrument text-lg text-white sm:text-xl;
  }
  p {
    @apply font-instrument text-base text-white sm:text-lg;
  }
  li {
    @apply font-instrument text-base text-gray-300 pb-1;
  }
}

.nav-link {
  @apply font-sfmono hidden sm:text-xs lg:text-sm md:block transition duration-75 text-white hover:text-secondary-light;
}

.link {
  @apply transition duration-75 text-secondary-light hover:text-secondary-dark hover:bg-secondary-light;
}

.footer-link {
  @apply font-sfmono text-xs lg:text-sm transition duration-75 text-white hover:text-secondary-light;
}

.hero-header {
  @apply my-0 text-2xl sm:text-3xl sm:my-1 lg:text-4xl text-white;
}

.section-header {
  @apply text-xl sm:text-xl lg:text-xl text-white font-sfmono;
}

.date {
  @apply font-sfmono text-sm text-gray-300 sm:text-base;
}

.button {
  @apply text-sm transition duration-150 ease-in-out hover:bg-secondary hover:ring-transparent bg-secondary-light bg-opacity-30 hover:bg-opacity-100
  text-white py-3 px-6 rounded-lg sm:text-lg font-sfmono shadow-[inset_0px_1.5px_3px_0px_rgba(148,163,184,0.5)] hover:shadow-[inset_0px_1.5px_3px_0px_rgba(120,127,138,0.5)];
}

.button-sm {
  @apply text-xs transition duration-150 ease-in-out hover:bg-secondary hover:ring-transparent bg-secondary-light bg-opacity-30
  text-white py-2 px-4  rounded-lg sm:text-sm font-sfmono shadow-[inset_0px_1px_2px_0px_rgba(148,163,184,0.5)] hover:shadow-[inset_0px_1px_2px_0px_rgba(120,127,138,0.5)];
}

.button-icon {
  @apply text-sm transition duration-150 ease-in-out hover:bg-secondary hover:ring-transparent bg-secondary-light bg-opacity-30 hover:bg-opacity-100
  text-white p-3 rounded-lg sm:text-lg font-sfmono shadow-[inset_0px_0.5px_1px_0px_rgba(148,163,184,0.5)];
}

.hover-border {
  @apply p-6 transition duration-150 border-2 rounded-lg border-transparent hover:border-secondary-light hover:bg-secondary-light hover:bg-opacity-20 -mt-6 mb-0;
}

@media only screen and (min-width: 768px) {
  .experience-grid {
    display: grid;
    grid-template-columns: 13.5em 1fr;
  }
}

@media only screen and (min-width: 768px) {
  .project-grid {
    display: grid;
    grid-template-columns: 4fr 3fr;
  }
}

@media only screen and (min-width: 768px) {
  .education-grid {
    display: grid;
    grid-template-columns: 1fr 3fr;
  }
}

@media only screen and (min-width: 768px) {
  .education-grid-alt {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }
}

@media only screen and (min-width: 768px) {
  .skills-grid {
    display: grid;
    grid-template-columns: 13.5em 1fr 1fr;
  }
}

@font-face {
  font-family: "Instrument Sans";
  font-weight: 400;
  src: url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@400&display=swap");
}

@font-face {
  font-family: "Instrument Sans";
  font-weight: 500;
  src: url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@500&display=swap");
}

@font-face {
  font-family: "Instrument Sans";
  font-weight: 600;
  src: url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@600&display=swap");
}

@font-face {
  font-family: "Instrument Sans";
  font-weight: 700;
  src: url("https://fonts.googleapis.com/css2?family=Instrument+Sans:wght@700&display=swap");
}

/* width */
::-webkit-scrollbar {
  @apply w-4;
}

/* Track */
::-webkit-scrollbar-track {
  border-radius: 100vh;
  background: #edf2f7;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(115, 150, 211);
  border-radius: 100vh;
  border: 3px solid #edf2f7;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-primary;
}
